import { Button, Stack, Typography } from "@infinitaslearning/pixel-design-system";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { closePepIframe } from "@utils/scoodleplay/iframe-communication";

const BTN_SIZE = "large";
const BTN_COLOR = "primary";
const BTN_VAR = "contained";
const LABEL_VAR = "buttonLarge";
const LABEL_COLOR = "common.white";

export const ReloadCTA = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const onButtonClick = () => {
    if (window.top === window.self) {
      router.reload();
      return;
    }
    closePepIframe();
  };

  return (
    <Button size={BTN_SIZE} color={BTN_COLOR} variant={BTN_VAR} onClick={onButtonClick}>
      <Typography variant={LABEL_VAR} color={LABEL_COLOR}>
        {t("platform-error.reload-cta")}
      </Typography>
    </Button>
  );
};

export const HomeCTA = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const onButtonClick = () => {
    if (window.top === window.self) {
      router.push("/");
      return;
    }
    closePepIframe();
  };

  return (
    <Button size={BTN_SIZE} color={BTN_COLOR} variant={BTN_VAR} onClick={onButtonClick}>
      <Typography variant={LABEL_VAR} color={LABEL_COLOR}>
        {t("platform-error.home-cta")}
      </Typography>
    </Button>
  );
};

export const HomePlusReloadCTA = () => {
  return (
    <Stack gap={1} direction="row">
      <HomeCTA />
      <ReloadCTA />
    </Stack>
  );
};
