import PlatformErrorImage from "@components/platform-error/platform-error-image";
import {
  CENTERED_SX,
  type ErrorComponentProps,
} from "@components/platform-error/platform-error-types";
import {
  getErrorMessageTSX,
  getGradientBackground,
} from "@components/platform-error/platform-error-utils";
import {
  Stack,
  type Theme,
  Typography,
  Divider,
  alpha,
} from "@infinitaslearning/pixel-design-system";

const RESPONSIVE_SX = {
  gap: 1,
  padding: 2,
  borderRadius: 4,
  height: "95%",
  width: "95%",
  boxShadow: (theme: Theme) =>
    `0 0 ${theme.spacing(2)} ${alpha(theme.pixel.color.palette.neutrals[0], 0.75)}`,
  backgroundImage: (theme: Theme) => getGradientBackground(theme),
  overflow: "auto",
  ...CENTERED_SX,
};

const RESPONSIVE_FULL = {
  ...RESPONSIVE_SX,
  height: "100%",
  width: "100%",
  padding: 4,
  borderRadius: 0,
  boxShadow: "",
};

export type ResponsiveErrorProps = ErrorComponentProps & {
  useFull: boolean;
};

export const ResponsiveError: React.FC<ResponsiveErrorProps> = ({
  errorType,
  title,
  t,
  errorCode,
  customMessage,
  message,
  children,
  CTA,
  useFull,
}) => {
  return (
    <Stack sx={useFull ? RESPONSIVE_FULL : RESPONSIVE_SX} data-testid="responsive-error">
      <Stack
        flexDirection="column"
        sx={{
          ...CENTERED_SX,
          gap: 3,
        }}
      >
        <PlatformErrorImage
          errorType={errorType}
          displayType={useFull ? "responsive_full" : "responsive"}
        />
        <Typography variant="h3">{title}</Typography>
      </Stack>
      <Divider orientation="horizontal" sx={{ my: 1 }} flexItem />
      {getErrorMessageTSX(t, errorCode, customMessage, message)}
      <Stack>{children}</Stack>
      <Divider orientation="horizontal" sx={{ my: 1 }} flexItem />
      <Stack>{CTA}</Stack>
    </Stack>
  );
};
